// libs
import * as _state from "../lib/state";
import * as _api from "../lib/api";

const init = (force = false) => {
	let transaction = _state.get("transaction/getTransaction");

	if (transaction === false || force === true) {
		_state.set("transaction/setTransaction", {
			payment_method: null,
			lines: [],
			affiliate: false,
			affiliateAddedOn: false,
			number_of_items: 0,
			total_without_discount: 0,
			total: 0,
		});
	}
};

const clearTransaction = () => {
	init(true);
};

const setPaymentMethod = (paymentMethod) => {
	let transaction = _state.get("transaction/getTransaction");

	transaction.payment_method = paymentMethod;
	saveTransaction(transaction);
};

const addWatch = (watchNumber) => {
	let transaction = _state.get("transaction/getTransaction");

	const exists = transaction.lines.findIndex((item) => item.watchNumber === watchNumber);

	if (exists === -1) {
		addItem({
			id: watchNumber,
			description: "Jaques Sermand Edition #" + watchNumber,
			watchNumber: watchNumber,
			rate: 5400,
			discount_rate: 0,
			quantity: 1,
			thumbnail: '../img/nft-image.png'
		});

		if (transaction.affiliate !== false) {
			addItem({
				id: 2 + watchNumber,
				parent_id: watchNumber,
				description: "Cufflinks",
				editable: false,
				rate: 600,
				discount_rate: 600,
				quantity: 1,
				thumbnail: '../img/cufflinks.jpeg'
			});
		}
	
		window.itemAdded = true;
	}
};

const addItem = async (data, update = true) => {
	let transaction = _state.get("transaction/getTransaction");

	// get the lines
	let lines = transaction.lines;

	// line exists?
	let line = lines.find((line) => line.id === data.id && line.parent_id === data.parent_id);

	// create new line
	if (!line) {
		let len = lines.push({
			id: data.id ?? false,
			parent_id: data.parent_id ?? null,
			addon: data.addon,
			watchNumber: data.watchNumber,
			editable: data.editable ?? true,
			quantity: data.quantity - 1,
			description: data.description,
			rate: data.rate,
			rate_without_discount: data.rate,
			discount_rate: data.discount_rate ?? 0,
			total: data.rate * data.quantity, //.toFixed(2),
			total_without_discount: data.rate * data.quantity,
			total_discount: data.quantity * data.discount_rate,
			thumbnail: data.thumbnail,
		});

		line = lines[len - 1];
	}

	// store the transaction
	_state.set("transaction/setTransaction", transaction);

	addQuantity(line, update);
};

const addQuantity = async (line, update = true) => {
	// get the transaction
	let transaction = _state.get("transaction/getTransaction");

	// set the quantity of the line
	transaction.lines
		.filter((ln) => {
			return line.id == ln.id;
		})
		.map((ln) => {
			ln.quantity = ln.quantity + 1;
			ln.total = ln.quantity * ln.rate;
			ln.total_without_discount = ln.quantity * ln.rate_without_discount;
			ln.total_discount = ln.quantity * ln.discount_rate;
		});

	saveTransaction(transaction);

	// store the transaction
	_state.set("transaction/setTransaction", transaction);
};

const subtractQuantity = async (line, forceDelete = false) => {
    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // delete line
    if (line.quantity == 1 || forceDelete === true) {
        let lines_delete = transaction.lines.filter((ln) => {
            return line.id == ln.id || ln.parent_id == line.id;
        });
        lines_delete.forEach((ln) => {
            transaction.lines.splice(transaction.lines.indexOf(ln), 1);
        });
    } else {
        // set the quantity of the line
        transaction.lines
            .filter((ln) => {
                return line.id == ln.id;
            })
            .map((ln) => {
                ln.quantity = ln.quantity - 1;
                ln.total = ln.quantity * ln.rate;
                ln.total_without_discount = ln.quantity * ln.rate_without_discount;
                ln.tickets = tickets;
            });

        // set the quantity of the children
        transaction.lines
            .filter((ln) => {
                return ln.parent_id != null && line.id == ln.parent_id;
            })
            .map((ln) => {
                ln.quantity = ln.quantity - ln.quantity_init;
                ln.total = ln.quantity * ln.rate;
                ln.total_without_discount = ln.quantity * ln.rate_without_discount;
            });
    }

    saveTransaction(transaction);
};

const saveTransaction = async (transaction) => {
	let number_of_items = 0;
	let total_without_discount = 0;
	let total_discount = 0;
	let total = 0;

	// calculate the lines
	transaction.lines.forEach((ln) => {
		ln.total = ln.quantity * (ln.rate - ln.discount_rate);
		total_discount += ln.total_discount;

		number_of_items = number_of_items + ln.quantity;
		total_without_discount = total_without_discount + ln.rate_without_discount;
		total += ln.total;
	});

	// set the totals to the transaction
	transaction.number_of_items = number_of_items;
	transaction.total_without_discount = total_without_discount;
	transaction.total_discount = total_discount;
	transaction.total = total;

	_state.set("transaction/setTransaction", transaction);
};

const validateAffiliateKey = async (key) => {
	let response = await _api.get("affiliate/key/:key", key);

	if (response && response.validKey === true) {
		let transaction = _state.get("transaction/getTransaction");

		transaction.affiliate = key;
		transaction.affiliateAddedOn = Date.now();
		saveTransaction(transaction);
	}
};

const resetAffiliate = () => {
	let transaction = _state.get("transaction/getTransaction");
	transaction.affiliate = false;
	transaction.affiliateAddedOn = false;
	saveTransaction(transaction);
};

export { init, clearTransaction, addWatch, subtractQuantity, validateAffiliateKey, resetAffiliate, setPaymentMethod };
