<template>
	<div
		:class="showPopup ? 'opacity-100 z-[999]' : 'opacity-0 -z-50'"
		class="relative"
		aria-labelledby="modal-title"
		role="dialog"
		aria-modal="true"
	>
		<div
			@click="closePopup()"
			class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-300"
			:class="showPopup ? 'opacity-100' : 'opacity-0'"
		></div>

		<div class="pointer-events-none fixed inset-0 z-10 overflow-y-auto">
			<div class="flex min-h-full w-screen items-end justify-center p-4 text-center sm:items-center sm:p-0">
				<div
					class="pointer-events-auto relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6"
					:class="
						showPopup
							? 'opacity-100 translate-y-0 sm:scale-100'
							: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
					"
				>
					<div>
						<div class="mt-3 sm:mt-5">
							<h3
								class="text-base font-semibold leading-6 text-gray-900 text-center"
								id="modal-title"
							>
								Oops... No Ethereum wallet found
							</h3>
							<div class="mt-2">
								<p class="text-sm text-gray-500 text-center">
									Jaques Sermand requires you to have an Ethereum wallet installed and it looks like
									your browser has none. To install an Ethereum wallet press the link below and come back to this page once your wallet is installed.
								</p>

								<div class="py-5">
									<div class="space-y-2">
										<a
											href="https://helpcenter.jaquessermand.ch"
											target="_blank"
											class="flex justify-between items-center text-xs text-gold underline bg-gray-50 py-2 px-2 rounded-md transition duration-300 hover:text-gray-600 hover:cursor-pointer"
										>
											<div>What is an Ethereum wallet?</div>
											<div>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="icon icon-tabler icon-tabler-external-link h-4 w-4"
													viewBox="0 0 24 24"
													stroke-width="1.5"
													fill="none"
													stroke-linecap="round"
													stroke-linejoin="round"
												>
													<path
														stroke="none"
														d="M0 0h24v24H0z"
														fill="none"
													/>
													<path
														d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6"
													/>
													<path d="M11 13l9 -9" />
													<path d="M15 4h5v5" />
												</svg>
											</div>
										</a>
										<a
											href="https://helpcenter.jaquessermand.ch"
											target="_blank"
											class="flex justify-between items-center text-xs text-gold underline bg-gray-50 py-2 px-2 rounded-md transition duration-300 hover:text-gray-600 hover:cursor-pointer"
										>
											<div>Why do i need an Ethereum wallet?</div>
											<div>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													class="icon icon-tabler icon-tabler-external-link h-4 w-4"
													viewBox="0 0 24 24"
													stroke-width="1.5"
													fill="none"
													stroke-linecap="round"
													stroke-linejoin="round"
												>
													<path
														stroke="none"
														d="M0 0h24v24H0z"
														fill="none"
													/>
													<path
														d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6"
													/>
													<path d="M11 13l9 -9" />
													<path d="M15 4h5v5" />
												</svg>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-5 sm:mt-3">
						<a
							href="https://helpcenter.jaquessermand.ch/docs/Tutorials/Metamask/how-to-install-metamask"
							target="_blank"
							class="inline-flex w-full justify-center rounded-md bg-gold text-white px-3 py-2 text-sm font-semibold border-2 border-gold shadow-sm transition duration-300 hover:text-black hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							<img
								class="h-5 w-5 mr-2"
								src="../assets/img/icons/metamask.svg"
								alt=""
							/>
							Install Ethereum Wallet
						</a>
					</div>
					<div class="">
						<button
							@click="closePopup()"
							type="button"
							class="inline-flex w-full justify-center rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// libs

export default {
	data() {
		return {
			showPopup: false,
		};
	},
	mounted() {
		window.addEventListener("click", (event) => {
			// this.windowValue = event.data.value;
			this.showPopup = window.noWalletFound;
		});
	},
	methods: {
		closePopup() {
			this.showPopup = false;
			window.noWalletFound = false;
		},
	},
	beforeUnmount() {
		window.removeEventListener("click");
	},
};
</script>
