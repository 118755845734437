export default {
    namespaced: true,
    state: {
        web3: null
    },

    getters: {
        getWeb3: state => state.web3,
    },

    mutations: {
        WEB3(state, web3) {
            state.web3 = web3;
        },
    },

    actions: {
        setWeb3({ commit }, web3) {
            commit('WEB3', web3);
        },
    }
}
