export default {
    namespaced: true,
    state: {
        transaction: false,
        affiliate: false,
    },

    getters: {
        getTransaction: (state) => (state.transaction ? state.transaction : false),
        getAffiliate: (state) => (state.affiliate ? state.affiliate : false),
    },

    mutations: {
        TRANSACTION(state, transaction) {
            state.transaction = transaction;
        },
        AFFILIATE(state,affiliate) {
            state.affiliate = affiliate;
        }
    },

    actions: {
        setTransaction({ commit }, transaction) {
            commit('TRANSACTION', transaction);
        },
        setAffiliate({ commit }, affiliate) {
            commit('AFFILIATE', affiliate);
        },
    },
};
