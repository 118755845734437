<template>
    <div>
        <router-view />
    </div>
</template>
<script>
export default {
    name: "app",
    data() {
        return {
            show: true,
        };
    },
};
</script>
