<template>
	<div
		:class="showPopup ? 'opacity-100 z-[999]' : 'opacity-0 -z-50'"
		class="relative"
		aria-labelledby="modal-title"
		role="dialog"
		aria-modal="true"
	>
		<div
			class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-300"
			:class="showPopup ? 'opacity-100' : 'opacity-0'"
		></div>

		<div class="pointer-events-none fixed inset-0 z-10 overflow-y-auto">
			<div class="flex min-h-full w-screen items-end justify-center text-center sm:items-center sm:p-0">
				<div
					class="pointer-events-auto relative transform overflow-hidden rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:pb-6"
					:class="
						showPopup
							? 'opacity-100 translate-y-0 sm:scale-100'
							: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
					"
				>
					<div>
						<div class="">
							<div class="bg-gold py-5 px-4 sm:px-6">
								<div class="flex items-center gap-2">
									<div class="flex items-center">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="icon icon-tabler icon-tabler-circle-check h-10 w-10 stroke-white"
											viewBox="0 0 24 24"
											stroke-width="1"
											fill="none"
											stroke-linecap="round"
											stroke-linejoin="round"
										>
											<path
												stroke="none"
												d="M0 0h24v24H0z"
												fill="none"
											/>
											<path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
											<path d="M9 12l2 2l4 -4" />
										</svg>
									</div>
									<div class="flex flex-col">
										<div class="text-xl font-semibold text-white leading-tight">
											Your watch has been added to your basket
										</div>
										<span class="text-white leading-3"
											>proceed to checkout to finalize your purchase</span
										>
									</div>
								</div>
							</div>

							<div class="px-4 sm:mx-6">
								<div class="py-5">
									<div class="flex items-center justify-center">
										<img
											class="h-32 w-32 object-cover rounded-md"
											src="../assets/img/nft-image.png"
											alt=""
										/>
									</div>
								</div>

								<div class="flex items-center justify-between">
									<button
										@click="closePopup()"
										type="button"
										class="inline-flex justify-center rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										Continue shopping
									</button>
									<button
										@click="checkout()"
										class="inline-flex justify-center rounded-md bg-gold text-white px-3 py-2 text-sm font-semibold border-2 border-gold shadow-sm transition duration-300 hover:text-black hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										Checkout
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// libs

export default {
	data() {
		return {
			showPopup: false,
		};
	},
	mounted() {
		window.addEventListener("click", (event) => {
			this.showPopup = window.itemAdded;
		});
	},
	methods: {
		closePopup() {
			this.showPopup = false;
			window.itemAdded = false;
		},
		checkout() {
			this.closePopup()
			this.$router.push("/checkout");
		},
	},
	beforeUnmount() {
		window.removeEventListener("click");
	},
};
</script>
