<template>
	<footer class="relative bg-black text-white border-t-2 border-gold pt-8 pb-6">
		<div class="container mx-auto px-4">
			<div class="flex flex-wrap text-left lg:text-left">
				<div class="w-full lg:w-6/12 px-4">
					<div>
						<img
							src="../assets/img/logo/logoFooter.svg"
							alt=""
						/>
					</div>
					<div class="mt-6 lg:mb-0 mb-6">
						<a
							href="https://twitter.com/Jaques_Sermand"
							target="_blank"
						>
							<button
								class="bg-white shadow-lg items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="icon icon-tabler icon-tabler-brand-twitter w-8 w-8 p-1"
									viewBox="0 0 24 24"
									stroke-width="1"
									stroke="#2c3e50"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										stroke="none"
										d="M0 0h24v24H0z"
										fill="none"
									/>
									<path
										d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z"
									/>
								</svg>
							</button>
						</a>
						<a
							href="https://discord.com/invite/nR2DYfBEsQ"
							target="_blank"
						>
							<button
								class="bg-white shadow-lg items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="icon icon-tabler icon-tabler-brand-discord h-8 w-8 p-1"
									viewBox="0 0 24 24"
									stroke-width="1"
									stroke="#2c3e50"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										stroke="none"
										d="M0 0h24v24H0z"
										fill="none"
									/>
									<circle
										cx="9"
										cy="12"
										r="1"
									/>
									<circle
										cx="15"
										cy="12"
										r="1"
									/>
									<path d="M7.5 7.5c3.5 -1 5.5 -1 9 0" />
									<path d="M7 16.5c3.5 1 6.5 1 10 0" />
									<path
										d="M15.5 17c0 1 1.5 3 2 3c1.5 0 2.833 -1.667 3.5 -3c.667 -1.667 .5 -5.833 -1.5 -11.5c-1.457 -1.015 -3 -1.34 -4.5 -1.5l-1 2.5"
									/>
									<path
										d="M8.5 17c0 1 -1.356 3 -1.832 3c-1.429 0 -2.698 -1.667 -3.333 -3c-.635 -1.667 -.476 -5.833 1.428 -11.5c1.388 -1.015 2.782 -1.34 4.237 -1.5l1 2.5"
									/>
								</svg>
							</button>
						</a>
						<a
							href="https://www.instagram.com/jaquessermand/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button
								class="bg-white shadow-lg items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="icon icon-tabler icon-tabler-brand-instagram h-8 w-8 p-1"
									viewBox="0 0 24 24"
									stroke-width="1"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										stroke="none"
										d="M0 0h24v24H0z"
										fill="none"
									/>
									<rect
										x="4"
										y="4"
										width="16"
										height="16"
										rx="4"
									/>
									<circle
										cx="12"
										cy="12"
										r="3"
									/>
									<line
										x1="16.5"
										y1="7.5"
										x2="16.5"
										y2="7.501"
									/>
								</svg>
							</button>
						</a>
						<a
							href="https://www.youtube.com/@jaquessermand"
							target="_blank"
							rel="noopener noreferrer"
						>
							<button
								class="bg-white shadow-lg items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="icon icon-tabler icon-tabler-brand-youtube h-8 w-8 p-1"
									viewBox="0 0 24 24"
									stroke-width="1"
									stroke="#2c3e50"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										stroke="none"
										d="M0 0h24v24H0z"
										fill="none"
									/>
									<rect
										x="3"
										y="5"
										width="18"
										height="14"
										rx="4"
									/>
									<path d="M10 9l5 3l-5 3z" />
								</svg>
							</button>
						</a>
					</div>
				</div>
				<div class="w-full lg:w-6/12 px-4">
					<div class="flex flex-wrap items-top mb-6">
						<div class="w-full lg:w-4/12 px-4 ml-auto">
							<span class="block uppercase text-blueGray-500 text-sm font-semibold mb-2"
								>Useful Links</span
							>
							<ul class="list-unstyled">
								<li>
									<a
										class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
										href="/about-watch"
										>Watch details</a
									>
								</li>
								<li>
									<a
										class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
										href="/roadmap"
										>Roadmap</a
									>
								</li>
								<li>
									<a
										class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
										href="/marcel-van-luit"
										>Marcel van Luit</a
									>
								</li>
								<!-- <li>
									<a
										class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
										href=""
										>Contruct your watch</a
									>
								</li>
								<li>
									<a
										class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
										href="/"
										>Helpcenter</a
									>
								</li>
								<li>
									<a
										class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
										href="/"
										>Opensea</a
									>
								</li> -->
							</ul>
						</div>
						<div class="w-full lg:w-4/12 px-4">
							<span class="block uppercase text-blueGray-500 text-sm font-semibold mb-2"
								>Other Resources</span
							>
							<ul class="list-unstyled">
								<li>
									<a
										href="https://helpcenter.jaquessermand.ch/"
										class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
										>Helpcenter</a
									>
								</li>
								<li>
									<a
										href="https://helpcenter.jaquessermand.ch/docs/category/faq"
										class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
										>FAQ</a
									>
								</li>
								<li>
									<router-link
										to="/terms-of-use"
										class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
										>Terms &amp; Conditions</router-link
									>
								</li>
								<li>
									<router-link
										to="/privacy-policy"
										class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
										>Privacy Policy</router-link
									>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<hr class="my-6 border-blueGray-300" />
			<div class="flex flex-wrap items-center md:justify-between justify-center">
				<div class="w-full md:w-4/12 px-4 mx-auto text-center">
					<div class="text-sm text-blueGray-500 font-semibold py-1">
						Copyright © <span id="get-current-year">2023</span> Jaques Sermand
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer",
};
</script>
