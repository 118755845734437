export const getNumberFormats = () => {
    return {
        nl: {
            currency: {
                style: 'currency',
                currency: 'eur',
                notation: 'standard',
            },
            coins: {
                style: 'decimal',
                notation: 'standard',
            },
            decimal: {
                style: 'decimal',
                minimumSignificantDigits: 3,
                maximumSignificantDigits: 5,
            },
            percentage: {
                style: 'decimal',
                notation: 'standard',
            },
            percent: {
                style: 'percent',
                useGrouping: false,
            },
        },
    };
};
