<template>
	<div
		@click="toggleBasketOpenState()"
		class="relative mx-5"
	>
		<div class="text-white rounded-full absolute h-full w-full flex items-center justify-center">
			<div class="font-bold text-sm mt-2">
				<span v-if="transaction">
					{{ transaction.lines.length }}
				</span>
				<span v-else>0</span>
			</div>
		</div>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			class="icon icon-tabler icon-tabler-shopping-bag h-10 w-10"
			viewBox="0 0 24 24"
			stroke-width="1.5"
			fill="none"
			stroke-linecap="round"
			stroke-linejoin="round"
		>
			<path
				stroke="none"
				d="M0 0h24v24H0z"
				fill="none"
			/>
			<path
				d="M6.331 8h11.339a2 2 0 0 1 1.977 2.304l-1.255 8.152a3 3 0 0 1 -2.966 2.544h-6.852a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304z"
			/>
			<path d="M9 11v-5a3 3 0 0 1 6 0v5" />
		</svg>
	</div>

	<div
		class="relative z-10"
		aria-labelledby="slide-over-title"
		role="dialog"
		aria-modal="true"
	>
		<div
			@click="toggleBasketOpenState()"
			:class="`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-in-out duration-500 ${
				this.open ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
			}`"
		></div>

		<div class="fixed inset-0 overflow-hidden pointer-events-none">
			<div class="absolute inset-0 overflow-hidden">
				<div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
					<div
						:class="`pointer-events-auto w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700 ${
							this.open ? 'translate-x-0' : 'translate-x-full'
						}`"
					>
						<div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
							<div class="px-4 sm:px-6">
								<div class="flex items-start justify-between">
									<h2
										class="text-base font-semibold leading-6 text-gray-900"
										id="slide-over-title"
									>
										Basket
									</h2>
									<div class="ml-3 flex h-7 items-center">
										<button
											@click="toggleBasketOpenState()"
											type="button"
											class="rounded-md bg-white text-gray-400 hover:text-gray-500"
										>
											<span class="sr-only">Close panel</span>
											<svg
												class="h-6 w-6"
												fill="none"
												viewBox="0 0 24 24"
												stroke-width="1.5"
												stroke="currentColor"
												aria-hidden="true"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>
							<div class="relative mt-6 flex-1 px-4 sm:px-6">
								<div
									v-if="transaction"
									class="w-full pt-10 lg:mt-0 col-start-10 col-end-13"
								>
									<div class="flex flex-col space-y-4 items-center">
										<!-- Item -->
										<div
											v-for="(line, index) in transaction.lines"
											v-bind:key="index"
											class="flex w-full justify-between"
										>
											<div class="flex">
												<div class="flex items-center">
													<div class="h-10 w-10 min-w-10 min-w-10">
														<img
															class="h-full w-full object-contain"
															:src="line.thumbnail"
															alt=""
														/>
													</div>
												</div>
												<div class="flex flex-col pl-3">
													<span class="text-base leading-5">{{ line.description }}</span>
													<div class="flex gap-2">
														<span class="text-xs">{{ $n(line.total, "currency") }}</span>
														<span
															v-if="line.discount_rate > 0"
															class="text-xs line-through text-red-400"
															>{{ $n(line.total_without_discount, "currency") }}</span
														>
													</div>
												</div>
											</div>
											<div class="flex items-center space-x-5 pr-5">
												<button
													v-if="line.editable === true"
													@click="subtractQuantity(line)"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														class="icon icon-tabler icon-tabler-trash h-5 w-5 transition duration-300 hover:stroke-red-600"
														viewBox="0 0 24 24"
														stroke-width="1.5"
														stroke="#2c3e50"
														fill="none"
														stroke-linecap="round"
														stroke-linejoin="round"
													>
														<path
															stroke="none"
															d="M0 0h24v24H0z"
															fill="none"
														/>
														<line
															x1="4"
															y1="7"
															x2="20"
															y2="7"
														/>
														<line
															x1="10"
															y1="11"
															x2="10"
															y2="17"
														/>
														<line
															x1="14"
															y1="11"
															x2="14"
															y2="17"
														/>
														<path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
														<path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
													</svg>
												</button>
												<div class="text-lg">{{ line.quantity }}</div>
											</div>
										</div>

										<div
											v-if="transaction && transaction.lines.length === 0"
											class="text-sm italic text-gray-600"
										>
											no items in basket
										</div>
									</div>
									<div class="h-px mt-4 w-full bg-gray-200"></div>
									<div
										class="pt-4 mt-4"
										v-if="transaction.total_discount > 0"
									>
										<div class="flex items-center justify-between text-sm w-full font-medium">
											<span>Subtotal</span>
											<span class="ms-auto flex-shrink-0 font-normal">
												{{ $n(transaction.total_without_discount, "currency") }}
											</span>
										</div>
										<div
											class="flex items-center justify-between text-sm w-full font-medium pl-2 text-gray-600"
										>
											<span class="italic">Discount</span>
											<span class="ms-auto flex-shrink-0 font-normal"
												>{{ $n(transaction.total_discount, "currency") }}
											</span>
										</div>
									</div>

									<div class="flex items-center justify-between py-4 lg:py-5 w-full font-medium">
										<span>Total</span>
										<span class="ms-auto flex-shrink-0 font-normal">{{
											$n(transaction.total, "currency")
										}}</span>
									</div>
									<div
										@click="checkout()"
										class="leading-4 w-full bg-gold text-white py-3 rounded-sm transition duration-150 hover:!text-darkblue text-center"
									>
										Checkout
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as _state from "../lib/state";
import * as _transaction from "../lib/transaction";

export default {
	name: "Basket",
	data() {
		return {
			open: false,
		};
	},
	async mounted() {},
	methods: {
		toggleBasketOpenState() {
            if (this.$route.name == 'checkout') {
                return;
            }

			this.open = !this.open;
		},
		checkout() {
			this.open = false;
			this.$router.push("/checkout");
		},
        subtractQuantity(line) {
			_transaction.subtractQuantity(line);
		},
	},
	computed: {
		transaction() {
			return _state.get("transaction/getTransaction");
		},
	},
};
</script>
