import axios from "axios";

const endpoints = {
	// settings
	"whitelist/enter": () => {
		return "/whitelist/enter";
	},
	"payment/request": () => {
		return "/payment/request";
	},
	"payment/:id/confirm": (id) => {
		return `/payment/${id}/confirm`;
	},
	"affiliate/key/:key": (key) => {
		return `/affiliate/key/${key}`;
	},
	"watch/validate/:watchNumber": (watchnumber) => {
		return `/watch/validate/${watchnumber}`;
	},
	"blog/:slug": (slug) => {
		return `/blog/${slug}`;
	},
};

const get = async (endPointId, params) => {
	let endPoint;
	try {
		if (endPointId.substr(0, 1) !== "/") {
			if (!endpoints[endPointId]) return [];
			endPoint = endpoints[endPointId](params);
			if (!endPoint) {
				let err = new Error("Endpoint ${endPointId} not found");
				err.status = 404;
				err.message = "Endpoint ${endPointId} not found";
				throw err;
			}
		} else {
			endPoint = endPointId;
		}
		let response = await axios
			.get(endPoint, {
				//prams: params,
				timeout: 10000,
				headers: {
					"Content-Type": "application/json",
					//'Accept': 'application/json',
					// Authorization: anonymous ? "" : "Bearer " + _token,
					// 'LocalizationTODO': 'nl',
				},
			})
			.then((response) => {
				// solves the error in api response
				if (typeof response.data.data !== "object") {
					return { data: response.data };
				}

				return response.data;
			});

		return "data" in response ? response.data : response;
	} catch (error) {
		// logger.error("API GET {} ERROR: {}", endPoint, error)();

		if (error.response.status === 401) {
			_auth.logout();

			// todo: notification
			// window.location = '/login';
		}
		return false;
	}
};

const post = async (endPointId, data, params) => {
	let endPoint;
	try {
		if (endPointId.substr(0, 1) !== "/") {
			if (!endpoints[endPointId]) return [];
			endPoint = endpoints[endPointId](params);
			if (!endPoint) {
				let err = new Error("Endpoint ${endPointId} not found");
				err.status = 404;
				err.message = "Endpoint ${endPointId} not found";
				throw err;
			}
		} else {
			endPoint = endPointId;
		}
		let response = await axios
			.post(endPoint, data, {
				// timeout: 2000,
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					// Authorization: anonymous ? "" : "Bearer " + _token,
				},
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				// logger.error("API GET {} ERROR: {}", endPoint, error)();

				if (error.response.status === 401) {
					_auth.logout();
					// todo: notification
				}

				return false;
			});

		return response;
		// return 'data' in response ? response.data : response;
	} catch (error) {
		// logger.error("API GET {} ERROR: {}", endPoint, error)();
		if (error.response.status === 401) {
			_auth.logout();
		}
		return false;
	}
};

export { get, post };
