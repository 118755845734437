import { createRouter, createWebHistory } from "vue-router";

// libs

// import * as _api from '../lib/api'
import Boot from "../views/Boot.vue";

const routes = [
	{
		path: "/",
		name: "boot",
		component: Boot,
		props: true,

		children: [
			{
				path: "/",
				name: "home",
				component: () => import("../views/Home.vue"),
			},
			{
				path: "/museums",
				name: "museums",
				component: () => import("../views/Museums.vue"),
			},
			{
				path: "/about-watch",
				name: "about",
				component: () => import("../views/About.vue"),
			},
			{
				path: "/nft",
				name: "nft",
				component: () => import("../views/Nft.vue"),
			},
			{
				path: "/construct-watch",
				name: "construct",
				component: () => import("../views/ConstructWatch.vue"),
			},
			{
				path: "/marcel-van-luit",
				name: "creator",
				component: () => import("../views/Creator.vue"),
			},
			{
				path: "/roadmap",
				name: "roadmap",
				component: () => import("../views/Roadmap.vue"),
			},
			{
				path: "/terms-of-use",
				name: "termsofuse",
				component: () => import("../views/TermsOfUse.vue"),
			},
			{
				path: "/privacy-policy",
				name: "privacypolicy",
				component: () => import("../views/PrivacyPolicy.vue"),
			},
			{
				path: "/blog/:slug",
				name: "blog",
				props: true,
				component: () => import("../views/BlogPage.vue"),
			},
			// {
			//     path: '/whitelist',
			//     name: 'whitelist',
			//     component: () => import('../views/Whitelist.vue'),
			// },
			// {
			//     path: '/whitelist/confirmation',
			//     name: 'whitelistconfirmation',
			//     component: () => import('../views/WhitelistConfirmation.vue'),
			// },
			{
				path: "/checkout",
				name: "checkout",
				component: () => import("../views/Checkout.vue"),
			},
			{
				path: "/confirmation",
				name: "confirmation",
				component: () => import("../views/Confirmation.vue"),
			},
			{
			    path: '/:pathMatch(.*)',
			    name: '404',
			    component: () => import('../views/404.vue'),
			},
		],
	},
];

const router = createRouter({
	scrollBehavior() {
		return { left: 0, top: 0 };
	},
	history: createWebHistory(),
	routes,
});

router.onError((error) => {
	if (/ChunkLoadError:.*failed./i.test(error.message) || /Loading.*chunk.*failed./i.test(error.message)) {
		window.location.reload();
	}
});

export default router;
