import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// import applicationState from './application-state';
// import clientAccess from './client-access';
import web3 from './web3';
import transaction from './transaction';


export default createStore({
    plugins: [createPersistedState()],
    modules: {
        // applicationState,
        // clientAccess,
        web3,
        transaction
    },
});
