<template>
	<div
		:class="showPopup ? 'opacity-100 z-[999]' : 'opacity-0 -z-50'"
		class="relative"
		aria-labelledby="modal-title"
		role="dialog"
		aria-modal="true"
	>
		<div
			@click="togglePopup()"
			class="fixed inset-0 transition-opacity duration-500"
			:class="showPopup ? 'translate-x-0' : 'translate-x-full'"
		></div>

		<div class="pointer-events-none fixed inset-0 z-10 overflow-y-auto">
			<div class="flex min-h-full max-w-screen overflow-hidden items-end justify-end p-4 text-center sm:p-0">
				<div
					class="pointer-events-auto relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-1 text-left shadow-xl transition-all duration-500 mr-5 mb-5 sm:w-full sm:max-w-md"
					:class="showPopup ? 'translate-x-0' : 'opacity-0 translate-x-full'"
				>
					<div class="texs-sm text-gray-400 text-center">
						<span>Support</span>
						<div class="w-full h-[1px] bg-gray-400"></div>
					</div>
					<div class="my-5">
						<a href="https://helpcenter.jaquessermand.ch/docs/Tutorials/Metamask/how-to-install-metamask" target="_blank">
							<div
								class="w-full transition duration-300 hover:bg-gray-200 px-4 rounded-md flex justify-between items-center"
							>
								<div class="">How to install metamask</div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="icon icon-tabler icon-tabler-external-link h-5 w-5 stroke-gray-400"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										stroke="none"
										d="M0 0h24v24H0z"
										fill="none"
									/>
									<path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
									<path d="M11 13l9 -9" />
									<path d="M15 4h5v5" />
								</svg>
							</div>
						</a>

						<a href="https://helpcenter.jaquessermand.ch/docs/FAQ/why-we-use-nft" target="_blank">
							<div
								class="w-full transition duration-300 hover:bg-gray-200 px-4 rounded-md flex justify-between items-center"
							>
								<div class="">Why we use NFT's</div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="icon icon-tabler icon-tabler-external-link h-5 w-5 stroke-gray-400"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										stroke="none"
										d="M0 0h24v24H0z"
										fill="none"
									/>
									<path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
									<path d="M11 13l9 -9" />
									<path d="M15 4h5v5" />
								</svg>
							</div>
						</a>

						<a href="https://helpcenter.jaquessermand.ch/docs/category/faq" target="_blank">
							<div
								class="w-full transition duration-300 hover:bg-gray-200 px-4 rounded-md flex justify-between items-center"
							>
								<div class="">FAQ's</div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									class="icon icon-tabler icon-tabler-external-link h-5 w-5 stroke-gray-400"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									fill="none"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<path
										stroke="none"
										d="M0 0h24v24H0z"
										fill="none"
									/>
									<path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
									<path d="M11 13l9 -9" />
									<path d="M15 4h5v5" />
								</svg>
							</div>
						</a>
					</div>
					<div>
						<a
							href="https://discord.com/invite/nR2DYfBEsQ"
							target="_blank"
						>
							<div
								class="w-full text-center border-2 border-gold rounded-md transition duration-300 hover:bg-gold hover:text-white"
							>
								Join Discord
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="fixed bottom-5 right-5">
		<div
			@click="togglePopup()"
			class="bg-gold text-white px-4 py-2 sm:py-0 rounded-md"
		>
			<div class="flex items-center gap-2">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="icon icon-tabler icon-tabler-help h-5 w-5 stroke-white"
					viewBox="0 0 24 24"
					stroke-width="1.5"
					fill="none"
					stroke-linecap="round"
					stroke-linejoin="round"
				>
					<path
						stroke="none"
						d="M0 0h24v24H0z"
						fill="none"
					/>
					<path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
					<path d="M12 17l0 .01" />
					<path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
				</svg>
				<span class="hidden md:block">Need help</span>
			</div>
		</div>
	</div>
</template>

<script>
// libs

export default {
	data() {
		return {
			showPopup: false,
		};
	},
	mounted() {},
	methods: {
		togglePopup() {
			this.showPopup = !this.showPopup;
		},
	},
};
</script>
